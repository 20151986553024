import React from "react";
import { useScreenDimensions } from "../hooks/useScreenDimensions";
import { useTranslations } from "../hooks/useTranslations";

// Contact info
import ContactInfo from "./data/contact.json";

// Icons
import {
  BiUser,
  BiTime,
  BiLogoLinkedinSquare,
  BiLogoInstagram,
  BiLogoGithub,
} from "react-icons/bi";
import { IoLocation } from "react-icons/io5";
import { MdDownload, MdEmail, MdPhone } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";

// Framer Motion
import { motion } from "framer-motion";
import { Languages } from "../translations/translations_interface";

interface Props {
  language: Languages;
}

export const FirstSection: React.FC<Props> = ({ language }) => {
    const { getScreenWidth, deviceType, responsiveView } = useScreenDimensions();
    const translations = useTranslations(language);

    const redirectToLinkedIn = () => {
      return window.open(ContactInfo.linkedIn, "_blank");
    }

    const redirectToGithub = () => {
      return window.open(ContactInfo.github, "_blank");
    }

    const redirectToInstagram = () => {
      return window.open(ContactInfo.instagram, "_blank");
    }

    const redirectToWhatsapp = () => {
      return window.open(ContactInfo.whatsapp, "_blank");
    }

    const redirectToEmail = () => {
      // With mailto: the user will be redirected to their default email client
      return window.open(`mailto://${ContactInfo.email}`, "_blank");
    }

    const redirectToPhone = () => {
      // With tel: the user will be redirected to their default phone client
      return window.open(`tel://${ContactInfo.phone}`, "_blank");
    }

    const redirectToCv = () => {
      // With tel: the user will be redirected to their default phone client
      return window.open(ContactInfo.cvDownloadLink, "_blank");
    }

    return (
            <motion.div
        initial={{ scale: 1.2 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.35 }}
        style={{
            minHeight: responsiveView(95, 95, 95, "h"),
            paddingTop: responsiveView(7.5, 3, 0, "h")
        }}
        className="flex flex-col lg:flex-row items-center mt-4 lg:mt-8" 
      >
        
        <img
            src="/avatar.jpg"
            alt="Ruben's Avatar"
            className="rounded-full bg-cover object-contain hover:scale-110 transition ease-linear border border-lightblue"
            style={{
              width: responsiveView(60, 40, 22.5, "w"),
              height: responsiveView(60, 40, 22.5, "w"),
              alignSelf: "center"
            }}
          />
        
        <motion.div
        initial={{
          x: 200,
        }} 
        whileInView={{
          x: 0,
          transition: {
            type: "spring",
            bounce: 0.15,
            duration: 1.2
          }
        }}
        viewport={{
          once: true
        }}
        className="flex flex-col justify-center max-w-[95%] lg:max-w-[85%] ml-0 mt-6 lg:mt-0 lg:ml-10">
            <b
              style={{ fontSize: "3.5rem" }}
              className="text-orange -mb-4"
            >
              {translations?.Homepage.iAm}
            </b>
            <span
              className="text-white dark:text-white mb-2 lg:mb-4"
              style={{ fontSize: "3.5rem", lineHeight: "3.5rem" }}
            >
              {translations?.Homepage.title}
            </span>

            <div className="flex flex-row items-center">
              <BiUser className="w-5 h-5 text-lightblue" />
              <b
                className="text-lightblue ml-4"
                style={{ fontSize: "1.15rem" }}
              >
                {translations?.Homepage.jobTitle}
              </b>
            </div>
            <div className="flex flex-row items-center">
              <BiTime className="w-5 h-5 text-lightblue" />
              <span
                className="text-lightblue ml-4"
                style={{ fontSize: "1.15rem" }}
              >
                {translations?.Homepage.jobExperience}
              </span>
            </div>
            <div className="flex flex-row items-center">
              <IoLocation className="w-5 h-5 text-lightblue" />
              <span
                className="text-lightblue ml-4"
                style={{ fontSize: "1.15rem" }}
              >
                {translations?.Homepage.location}
              </span>
            </div>

            <div className="mt-6 text-white">
              <span style={{ fontSize: "1.4rem", fontWeight: "300" }}>
                {translations?.Homepage.aboutMe}
              </span>
            </div>

            <div className="flex flex-col lg:flex-row text-lighterblue mt-12">
              <div className="flex justify-center mt-2">
              <button onClick={redirectToLinkedIn} className="bg-blue/40 rounded hover:bg-blue/20 transition ease-linear p-4 mx-2">
                <BiLogoLinkedinSquare className="w-7 h-7" />
              </button>
              <button onClick={redirectToGithub} className="bg-blue/40 rounded hover:bg-blue/20 transition ease-linear p-4 mx-2">
                <BiLogoGithub className="w-7 h-7" />
              </button>
              <button onClick={redirectToInstagram} className="bg-blue/40 rounded hover:bg-blue/20 transition ease-linear p-4 mx-2">
                <BiLogoInstagram className="w-7 h-7" />
              </button>
              <button onClick={redirectToWhatsapp} className="bg-blue/40 rounded hover:bg-blue/20 transition ease-linear p-4 mx-2">
                <BsWhatsapp className="w-7 h-7" />
              </button>
              </div>

              <div className="flex items-start justify-start mt-6 lg:mt-2 w-full lg:w-auto">
              <button onClick={redirectToEmail} className="flex w-full flex-row items-center justify-center bg-blue/40 rounded hover:bg-blue/20 transition ease-linear p-4 mx-2">
                <MdEmail className="w-7 h-7" />
                <div className="mx-2">{translations?.Buttons.sendEmail}</div>
              </button>
              </div>

              <div className="flex items-start justify-start mt-2 lg:mb-0 w-full lg:w-auto">
              <button onClick={redirectToPhone} className="flex w-full flex-row items-center justify-center bg-blue/40 rounded hover:bg-blue/20 transition ease-linear p-4 mx-2">
                <MdPhone className="w-7 h-7" />
                <div className="mx-2">{translations?.Buttons.callMe}</div>
              </button>
              </div>

              <div className="flex items-start justify-start mt-2 mb-6 lg:mb-0 w-full lg:w-auto">
              <button onClick={redirectToCv} className="flex w-full flex-row items-center justify-center bg-orange/70 rounded hover:bg-orange/50 transition ease-linear p-4 mx-2">
                <MdDownload className="w-7 h-7" />
                <div className="mx-2">{translations?.Buttons.downloadCv}</div>
              </button>
              </div>

            </div>
          </motion.div>
        
      </motion.div>
    )
}