// This hook is used to get translations for "de" and "en" languages.
import React from 'react';
import { Translations } from '../translations/translations_interface';

import { German } from '../translations/de';
import { English } from '../translations/en';

export const useTranslations = (language: "en" | "de") => {
    const [translations, setTranslations] = React.useState<Translations | null>(null);

    React.useEffect(() => {
        if(language === "en") {
            setTranslations(English);
        } else if(language === "de") {
            setTranslations(German);
        } else {
            setTranslations(English);
        }
    }, [language]);

    return translations;

}
    