import React, { useEffect, useRef } from "react";
import { useScreenDimensions } from "../hooks/useScreenDimensions";
import { useTranslations } from "../hooks/useTranslations";

// Framer Motion
import {
  AnimatePresence,
  Variants,
  motion,
  useAnimate,
  useCycle,
  useInView,
} from "framer-motion";
import useOnScreen from "../hooks/useOnScreen";

// Items
import Items from "./data/portfolio.json";
import { Languages } from "../translations/translations_interface";

interface Props {
  language: Languages;
}

export const PortfolioSection: React.FC<Props> = ({ language }) => {
  const { getScreenWidth, deviceType, responsiveView } = useScreenDimensions();
  const translations = useTranslations(language);
  const portfolioViewRef = useRef<HTMLDivElement>(null);

  // Items
  const mobileApps = Items.items.filter((item) => item.type === "mobile");
  const webApps = Items.items.filter((item) => item.type === "web");
  //

  const isInView = useInView(portfolioViewRef, {
    margin: `0px 0px`,
    once: true,
  });

  const pItemsVariants: Variants = {
    offscreen: {
      y: 300,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  return (
    <div ref={portfolioViewRef}>
      <motion.div
        style={{
          minHeight: responsiveView(95, 95, 95, "h"),
        }}
        className={`flex flex-col justify-center items-center lg:items-start max-w-[95%] overflow-hidden mt-4 lg:mt-8`}
      >
        <motion.div
                initial={{
                  y: "50%",
                }}
                whileInView={{
                  y: 0,
                  transition: {
                    type: "spring",
                    bounce: 0.4,
                    duration: 0.8,
                  },
                }}
                viewport={{
                  once: true,
                }}
              >
          <b style={{ fontSize: "3.5rem" }} className="text-orange -mb-4">
            Portfolio
          </b>
        </motion.div>

        <span
          style={{
            fontWeight: 400,
            lineHeight: "0.25rem",
          }}
          className="text-lighterblue"
        >
          {translations?.Portfolio.subtitle}
        </span>
        
        <div
                className="flex flex-col text-2xl max-w-[95%]"
              >
        {webApps.map((item, index) => {
            return (
              <div
                key={`${item.name}`}
                className="text-2xl pt-12"
              >
                <div className="flex flex-col max-w-full lg:max-w-[70%]">
                  <b
                    className="uppercase text-lightblue"
                    style={{ fontSize: "1.75rem" }}
                  >
                    {item.name}
                  </b>
                  <span
                    className="text-lighterblue"
                    style={{ fontSize: "1.05rem", fontWeight: "100" }}
                  >
                    {item.description[language]}
                  </span>
                  <span className="text-blue" style={{ fontSize: "1rem" }}>
                    — {item.category} | {item.timeframe}
                  </span>
                </div>

                <div className="flex flex-row flex-wrap pt-8 cursor-pointer">
                  {item.techstack.map((tech, index) => {
                    return (
                      <div  key={`${tech}_${index}_${item.name}`} className="flex items-center bg-black/30 hover:bg-black/50 transition ease-linear text-white/70 rounded-full px-4 py-2 mr-4 mt-2">
                        <span className="text-sm">{tech}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col pt-8">
                  {item.media.map((media, index) => {
                    return (
                      <div key={`media_${index}_${item.name}`} className="rounded-md px-4 py-2 mr-4">
                        <img src={media.url} className="rounded-md"  />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      
        <div className="grid grid-cols-1 lg:grid-cols-2 pt-12 max-w-[95%]">
          {mobileApps.map((item, index) => {
            return (
              <motion.div
              key={`${item.name}`}
                initial={{
                  y: "50%",
                }}
                whileInView={{
                  y: 0,
                  transition: {
                    type: "spring",
                    bounce: 0.4,
                    duration: 0.8,
                  },
                }}
                viewport={{
                  once: true,
                  margin: "100px 100px",
                }}
                className="flex-1 pt-12 text-2xl"
              >
                <div className="flex flex-col max-w-full lg:max-w-[80%]">
                  <b
                    className="uppercase text-lightblue"
                    style={{ fontSize: "1.75rem" }}
                  >
                    {item.name}
                  </b>
                  <span
                    className="text-lighterblue"
                    style={{ fontSize: "1.05rem", fontWeight: "100" }}
                  >
                    {item.description[language]}
                  </span>
                  <span className="text-blue" style={{ fontSize: "1rem" }}>
                    — {item.category} | {item.timeframe}
                  </span>
                </div>

                <div className="flex flex-row flex-wrap pt-8 cursor-pointer">
                  {item.techstack.map((tech, index) => {
                    return (
                      <div key={`${tech}_${index}_${item.name}`} className="flex items-center bg-black/30 hover:bg-black/50 transition ease-linear text-white/70 rounded-full px-4 py-2 mr-4 mt-2">
                        <span className="text-sm">{tech}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="flex pt-8">
                  {item.media.map((media, index) => {
                    return (
                      <div className="rounded-md px-4 py-2 mr-4" key={`media_${index}_${item.name}`}>
                        <img src={media.url} className="rounded-md"  />
                      </div>
                    );
                  })}
                </div>
              </motion.div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};
