import { useEffect, useState } from 'react';
import './styles/styles.css'
import { FirstSection } from './sections/FirstSection';
import { PortfolioSection } from './sections/Portfolio';
import { useScreenDimensions } from './hooks/useScreenDimensions';
import { useTranslations } from './hooks/useTranslations';

import {Helmet} from "react-helmet";

function App() {
  const { getScreenWidth, deviceType, responsiveView } = useScreenDimensions();

  // Multi-language
  const getUserLanguage = () => {
    const userLanguage = navigator.language;
    const langCode = userLanguage.substring(0, 2); // Extract the first two characters for language code (e.g., 'en' from 'en-US')
    if(langCode === "de") {
      return "de"
    } else {
      return "en"
    }
  };
  const [language, setLanguage] = useState<"en" | "de">(getUserLanguage());
  console.log(`Language: ${language}`);
  const translations = useTranslations(language);
  

  useEffect(() => {
    document.title = "Ruben Sindrestean: About Me & Portfolio"
  })
  // Refs
  return (
    <>
    <Helmet>
        <title>Ruben Sindrestean: About Me & Portfolio</title>
        <meta name="description" content="Ruben Sindrestean: About Me & Portfolio" />
      </Helmet>
      <div key={deviceType} className='main flex justify-center relative'>
      <div className='absolute flex flex-row text-white' style={{
        top: responsiveView(2.5, 2.5, 5, "h"),
        right: getScreenWidth(2.5),
      }}>
        <button 
        style={{
          fontWeight: language === "en" ? "700" : "400"
        }}
        onClick={() => setLanguage("en")}
        className={`mx-2 hover:bg-orange p-2 rounded transition ease-linear`}>English</button>
        <button 
        onClick={() => setLanguage("de")}
        style={{
          fontWeight: language === "de" ? "700" : "400"
        }}
        className={`mx-2 hover:bg-orange p-2 rounded transition ease-linear`}>Deutsch</button>
      </div>

      <div className='flex flex-col max-w-[97.5%] lg:max-w-[80%] m-1 lg:m-4'>
        <FirstSection language={language} />
        <PortfolioSection language={language} />
       </div>
      </div>
      </>
  );
}

export default App;
