import { Translations } from "./translations_interface";

export const German: Translations = {
    Homepage: {
        title: "Ruben Sindrestean",
        description: "Curriculum Vitae & Portfolio",
        jobTitle: "Full-Stack Software Entwickler",
        jobExperience: "10+ Jahre Erfahrung",
        location: "Tuttlingen, Baden-Württemberg, Deutschland",
        aboutMe: "Ich bin ein sehr erfahrener, self-taught Full-Stack-Entwickler mit einer jahrzehntelangen Leidenschaft für das Programmieren. Ich beherrsche eine breite Palette von Programmiersprachen und Technologien, darunter JavaScript, TypeScript, C#, Python, HTML, CSS, React, React Native, Next.js, Apollo, SQL- und No-SQL-Datenbanken, REST-APIs, GraphQL, Express, Node.js und viele mehr. Ich schließe die Lücke zwischen Front-End- und Back-End-Entwicklung und schaffe nahtlose und benutzerfreundliche Erlebnisse für Web- und Mobilplattformen.",
        iAm: "Ich bin",
    },
    Buttons: {
        sendEmail: "E-Mail senden",
        callMe: "Rufen Sie mich an",
        downloadCv: "Lebenslauf herunterladen",
    },
    Portfolio: {
        subtitle: "Einige meiner Arbeiten als Freelancer",
    }
}