import { Translations } from "./translations_interface";

export const English: Translations = {
    Homepage: {
        title: "Ruben Sindrestean",
        description: "Curriculum Vitae & Portfolio",
        jobTitle: "Full-Stack Software Developer",
        jobExperience: "10+ years of experience",
        location: "Tuttlingen, Baden-Württemberg, Germany",
        aboutMe: "I'm a highly experienced self-taught full-stack developer with a decade-long passion for coding. Proficient in a wide array of programming languages and technologies, including JavaScript, TypeScript, C#, Python, HTML, CSS, React, React Native, Next.js, Apollo, SQL & No-SQL Databases, REST APIs, GraphQL, Express, Node.js and many more. I excel at bridging the gap between front-end and back-end development, crafting seamless and user-friendly experiences across web and mobile platforms.",
        iAm: "I am",
    },
    Buttons: {
        sendEmail: "Send Email",
        callMe: "Call Me",
        downloadCv: "Download CV",
    },
    Portfolio: {
        subtitle: "Some of my work as a freelancer",
    }
}